import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cars',
    name: 'CarsView',
    component: function () {
        return import('../views/CarsView.vue')

    }
  },
  {
    path: '/newcar',
    name: 'NewCar',
    component: function () {
        return import('../views/NewCar.vue')

    }
  },
  {
    path: '/car/:id',
    name: 'CarView',
    component: function () {
      
      return import('../views/CarView.vue')                    
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
